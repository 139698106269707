// eslint-disable-next-line import/default
import AsyncAlpine from 'async-alpine'
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)
AsyncAlpine.init(Alpine)

for (const [name, component] of Object.entries({
  ...import.meta.glob('../../components/**/*.js')
}))
  AsyncAlpine.data(identifierForGlobKey(name), () => component())

for (const [name, component] of Object.entries({
  ...import.meta.glob('../alpine/**/*.js')
})) {
  AsyncAlpine.data(identifierForGlobKey(name, false), () => component())
}

AsyncAlpine.start()

Alpine.start()

function identifierForGlobKey(key, controller = true) {
  const COMPONENTS_FILENAME_REGEX = /^(?:.*?(?:components)\/|\.?\.\/)?(.+)(?:[/_-](component)\..+?)$/
  const REGULAR_FILENAME_REGEX = /^(?:.*?(?:alpine)\/|\.?\.\/)?(.+)(?:\..+?)$/
  const logicalName = (key.match(controller ? COMPONENTS_FILENAME_REGEX : REGULAR_FILENAME_REGEX) || [])[1]
  if (logicalName) return logicalName.replace(/\//g, '__')
}
