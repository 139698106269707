// Entry point for the build script in your package.json

import { Turbo } from '@hotwired/turbo-rails'
import Rails from '@rails/ujs'
import { StreamActions } from '@hotwired/turbo'

import '@/initializers'

// Load Stimulus controllers
import '@/controllers'

window.Turbo = Turbo

// Start UJS
window.Rails = Rails
if (!window._rails_loaded) Rails.start()

// Turbo stream custom actions
StreamActions.matomo_pageview = function () {
  const gdprRouteTag = document.querySelector('meta[name="gdpr-route"]')
  const gdprRoute = this.getAttribute('gdpr_route')
  window._paq.push(['setReferrerUrl', gdprRouteTag.content])
  window._paq.push(['setCustomUrl', gdprRoute])
  window._paq.push(['setDocumentTitle', document.title])
  window._paq.push(['trackPageView'])

  gdprRouteTag.content = gdprRoute
}
